import $ from 'jquery';
import jQuery from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = jQuery;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


// ######################################################################################
// Back to Top
// ######################################################################################

$(document).foundation();

$(document).ready(function(){

	// Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
	var back_to_top_button = ['<a href="#top" class="back-to-top"><i class="fas fa-arrow-up"></i></a>'].join("");
	$("body").append(back_to_top_button)

	// Der Button wird ausgeblendet
	$(".back-to-top").hide();

	// Funktion für das Scroll-Verhalten
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});

		$('.back-to-top').click(function () { // Klick auf den Button
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});
	});

});

// ######################################################################################
// Hamburger
// ######################################################################################

$(document).ready(function(){
	$(".hamburger").click(function(){
	  $(this).toggleClass("is-active");
	});
  });


// ######################################################################################
// Scrolling
// ######################################################################################

$(document).ready(function(){
    $('.page-scroll').bind('click',function (e) {
        e.preventDefault();
        var target = $(this).attr('data-href'),
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top-(0)  
        }, 900, 'swing');
    });
});